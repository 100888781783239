import * as yup from 'yup';

import {
  EmailInput,
  PasswordInput,
  PhoneNumberInput,
  TextInputField,
} from '../../molecules/TextInput';
import { Form, Formik } from 'formik';
import {
  MASTERCARD_OFFER_TERMS_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
} from '@/utils/constants';
import React, { useState } from 'react';

// import Button from '../../atoms/Button';
// import Divider from '../../atoms/Divider';
import FormSubmitButton from '@/components/molecules/FormSubmitButton';
// import Image from 'next/image';
import Link from 'next/link';
// import Typography from '../../atoms/Typography';
// import googleLogo from '@/images/google.svg';
import qs from 'qs';
// import * as amplitude from '@amplitude/analytics-browser';
import { useRouter } from 'next/router';
import useSignup from '@/hooks/useSignup';
import useSocialAuthPopup from '@/hooks/useSocialAuthPopup';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { useSignUpContinue } from '@/hooks/amplitude/useSignUpContinue';
import { useSignUpGoogleauth0 } from '@/hooks/amplitude/useSignUpGoogleauth0';
import Typography from '@/components/atoms/@next/Typography';
import useContentfulSignUp from '@/hooks/contenful/useContentfulSignUp';
import VerticalLayout from '@/components/layouts/VerticalLayout';
import LoadingSpinner from '@/components/atoms/LoadingSpinner';

export interface SignupFormValues {
  email: string;
  password: string;
  name: string;
  lastname: string;
  phone: string;
}

export interface SignupFormPageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  onSuccess: AnyFunction;
  onLoginLinkClick?: AnyFunction;
  error?: string;
  promocode?: {
    code: string | undefined;
    partner: string | undefined;
  };
}

export interface SignupFormContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  onSuccess: AnyFunction;
  onSocialSignupClick: (connection: 'google-oauth2') => unknown;
  onLoginLinkClick?: AnyFunction;
  error?: string;
  promocode?: {
    code: string | undefined;
    partner: string | undefined;
  };
}

export interface SignupFormProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  onSubmit: (values: SignupFormValues) => any;
  onSocialSignupClick: (connection: 'google-oauth2') => unknown;
  onLoginLinkClick?: AnyFunction;
  error?: string;
  errorVariant?: 'error' | 'warning' | 'success';
  contentfulSigUp: {
    title: string;
    description: string;
    email: string;
    password: string;
    SignUp: string;
    SignIn: string;
    Account: string;
    agree: string;
    Term: string;
    and: string;
    Privacy: string;
    offer: string;
    validationSchema: {
      email: string;
      required: string;
      min: string;
      firstNameMin: string;
      firstNameMax: string;
      lastNameMin: string;
      lastNameMax: string;
      phoneMin: string;
    };
    phoneNumber: string;
    lastName: string;
    firstName: string;
    subTitle: string;
  };
}

export const SignupFormPageContainer: React.FC<SignupFormPageContainerProps> = ({
  onSuccess,
  error,
  promocode,
}) => {
  const router = useRouter();
  const [socialAuthError] = useState<string>();
  const { signUpContinueTrack } = useSignUpContinue();
  const { signUpGoogleauth0Track } = useSignUpGoogleauth0();

  return (
    <SignupFormContainer
      onSuccess={(onSuccessResponse) => {
        //amplitude.setUserId(onSuccessResponse.id);
        signUpContinueTrack();
        onSuccess(onSuccessResponse);
      }}
      onSocialSignupClick={(connection) => {
        signUpGoogleauth0Track();
        router.push(
          `/api/auth/signup?connection=${connection}&${qs.stringify({
            redirectTo: router.query.redirectTo,
          })}`
        );
      }}
      error={socialAuthError || error}
      promocode={promocode}
    />
  );
};

export const SignupFormModalContainer: React.FC<SignupFormPageContainerProps> = ({
  onSuccess,
  onLoginLinkClick,
  error,
}) => {
  const { showPopup } = useSocialAuthPopup();
  const [socialAuthError, setSocialAuthError] = useState<string>();
  const { signUpContinueTrack } = useSignUpContinue();
  const { signUpGoogleauth0Track } = useSignUpGoogleauth0();

  return (
    <SignupFormContainer
      onSuccess={(onSuccessResponse) => {
        signUpContinueTrack();
        onSuccess(onSuccessResponse);
      }}
      onSocialSignupClick={(connection) =>
        showPopup(connection, (err: any, result: any) => {
          if (err) {
            const { error, description, errorDescription, error_description } = err;
            setSocialAuthError(description || errorDescription || error_description || error);
            return;
          }
          signUpGoogleauth0Track();
          onSuccess(result);
        })
      }
      onLoginLinkClick={onLoginLinkClick}
      error={socialAuthError || error}
    />
  );
};

export const SignupFormContainer: React.FC<SignupFormContainerProps> = ({
  onSuccess,
  onSocialSignupClick,
  onLoginLinkClick,
  error,
  promocode,
}) => {
  const { signup, error: signupError } = useSignup();
  const page = useContentfulSignUp();

  if (!page) {
    return (
      <VerticalLayout className="flex h-screen items-center justify-center">
        <LoadingSpinner />
      </VerticalLayout>
    );
  }

  const {
    items: {
      title,
      description,
      email,
      password,
      SignUp,
      SignIn,
      Account,
      agree,
      Term,
      and,
      Privacy,
      offer,
      validationSchema,
      errorSchema,
      lastName,
      firstName,
      phoneNumber,
      subTitle,
    },
  } = page;  

  const onSubmit = async ({ email, password, name, lastname, phone }: SignupFormValues) => {
    try {
      const { data: user } = await signup({
        email,
        password,
        name,
        lastname,
        phone,
        code: promocode?.code,
        partner: promocode?.partner,
      });
      onSuccess(user);
    } catch (error) {
      // noop: handled by useSignup hook
    }
  };

  return (
    <SignupForm
      onSubmit={onSubmit}
      error={errorSchema.data[signupError?.shape?.data.code!] || error}
      //error={signupError?.message || (error as string)}
      onSocialSignupClick={onSocialSignupClick}
      onLoginLinkClick={onLoginLinkClick}
      errorVariant={
        //@ts-ignore
        signupError?.data?.code === 'ERROR_CODE_EMAIL_NOT_VERIFIED' || signupError?.data?.code === 'ERROR_CODE_REGISTER_EMAIL_NOT_VERIFIED' ? 'success' : 'error'
      }
      contentfulSigUp={{
        title: title.text,
        description: description.text,
        email: email.text,
        password: password.text,
        SignUp: SignUp.text,
        SignIn: SignIn.text,
        Account: Account.text,
        agree: agree.text,
        Term: Term.text,
        and: and.text,
        Privacy: Privacy.text,
        offer: offer.text,
        validationSchema: {
          required: validationSchema.data.required,
          email: validationSchema.data.email,
          min: validationSchema.data.min,
          firstNameMax: validationSchema.data.firstNameMax,
          firstNameMin: validationSchema.data.firstNameMin,
          lastNameMax: validationSchema.data.lastNameMax,
          lastNameMin: validationSchema.data.lastNameMin,
          phoneMin: validationSchema.data.phoneMin,
        },
        phoneNumber: phoneNumber.text,
        lastName: lastName.text,
        firstName: firstName.text,
        subTitle: subTitle.text,
      }}
    />
  );
};

export const SignupForm: React.FC<SignupFormProps> = ({
  onSubmit,
  // onSocialSignupClick,
  onLoginLinkClick,
  error,
  errorVariant = 'error',
  contentfulSigUp,
}) => {
  const router = useRouter();
  const [passVisible, setPassVisible] = useState(false);

  return (
    (<Formik
        initialValues={{ email: '', password: '', name: '', lastname: '', phone: '' }}
        validationSchema={yup.object().shape({
          email: yup
            .string()
            .email(contentfulSigUp.validationSchema.email)
            .required(contentfulSigUp.validationSchema.required),
          password: yup
            .string()
            .min(8, contentfulSigUp.validationSchema.min)
            .required(contentfulSigUp.validationSchema.required),
          name: yup
            .string()
            .min(2, contentfulSigUp.validationSchema.firstNameMin)
            .max(25, contentfulSigUp.validationSchema.firstNameMax)
            .required(contentfulSigUp.validationSchema.required),
          lastname: yup
            .string()
            .min(2, contentfulSigUp.validationSchema.lastNameMin)
            .max(25, contentfulSigUp.validationSchema.lastNameMax)
            .required(contentfulSigUp.validationSchema.required),
          phone: yup.string().min(12, contentfulSigUp.validationSchema.phoneMin).required(contentfulSigUp.validationSchema.required),
        })}
        onSubmit={onSubmit}
      >
      {({ isValid, isSubmitting, setFieldValue }) => (
        <Form className="my-10 flex w-full flex-col gap-6 md:my-0">
          <Typography variant="heading" size="56px" className="text-center !font-normal">
            {contentfulSigUp.title}
            <br/>
            {contentfulSigUp.subTitle}
          </Typography>
          <Typography variant="body" size="14px" className="text-center xl:text-left !font-medium">
            {contentfulSigUp.description}
          </Typography>

          <div>
            <EmailInput
              label={contentfulSigUp.email}
              name="email"
              disabled={isSubmitting}
              autoComplete="username"
              placeholder={contentfulSigUp.email}
            ></EmailInput>
            <PasswordInput
              label={contentfulSigUp.password}
              name="password"
              disabled={isSubmitting}
              type={passVisible ? 'text' : 'password'}
              autoComplete="new-password"
              placeholder={contentfulSigUp.password}
              iconRight={passVisible ? faEyeSlash : faEye}
              onRightIconClick={() => setPassVisible(!passVisible)}
            ></PasswordInput>
            <TextInputField
              name="name"
              label={contentfulSigUp.firstName}
              placeholder="Jane"
              disabled={isSubmitting}
              autoComplete="given-name"
              onChange={(e) => {
                let regex = e.target.value.replace(/[^a-zA-Z]+/g, '');
                setFieldValue('name', regex);
              }}
            />
            <TextInputField
              name="lastname"
              label={contentfulSigUp.lastName}
              placeholder="Doe"
              disabled={isSubmitting}
              autoComplete="family-name"
              onChange={(e) => {
                let regex = e.target.value.replace(/[^a-zA-Z]+/g, '');
                setFieldValue('lastname', regex);
              }}
            />
            <PhoneNumberInput
              name="phone"
              label={contentfulSigUp.phoneNumber}
              autoComplete="phone"
              disabled={isSubmitting}
              iconLeft={undefined}
            />

            <FormSubmitButton
              loading={isSubmitting}
              disabled={!isValid}
              error={error}
              errorVariant={errorVariant}
            >
              {contentfulSigUp.SignUp}
            </FormSubmitButton>
          </div>
          <div className="text-center font-medium">
            <Typography variant="body" size="16px">
              {contentfulSigUp.Account}{' '}
            </Typography>
            {!onLoginLinkClick && (
              <Link
                href={`/login${qs.stringify(router.query, { addQueryPrefix: true })}`}
                legacyBehavior>
                <Typography
                  variant="body"
                  size="16px"
                  className="cursor-pointer !font-semibold text-sumawealth-red-80 underline"
                >
                  {contentfulSigUp.SignIn}
                </Typography>
              </Link>
            )}
            {onLoginLinkClick && (
              <div className="cursor-pointer underline" onClick={onLoginLinkClick}>
                <Typography
                  variant="body"
                  size="16px"
                  className="cursor-pointer !font-semibold text-sumawealth-red-80 underline"
                >
                  {contentfulSigUp.SignIn}
                </Typography>
              </div>
            )}
          </div>
          {/* <div className="relative my-4">
            <Divider />
            <Typography
              variant="label"
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform bg-pampas px-4 text-center"
            >
              or
            </Typography>
          </div> */}
          {/* <Button
            variant="transparent"
            type="button"
            iconComponent={<Image src={googleLogo} width={24} height={24} alt="Google logo" />}
            disabled={isSubmitting}
            onClick={() => onSocialSignupClick('google-oauth2')}
          >
            Continue with Google
          </Button> */}
          <div className="text-center text-sm font-medium">
            <Typography variant="body" size="16px">
              {contentfulSigUp.agree}{' '}
            </Typography>

            <Link
              href={TERMS_AND_CONDITIONS_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-suma underline"
              legacyBehavior>
              <Typography
                variant="body"
                size="16px"
                className="cursor-pointer !font-semibold text-sumawealth-red-80 underline"
              >
                {contentfulSigUp.Term}{' '}
              </Typography>
            </Link>

            <Typography variant="body" size="16px">
              {contentfulSigUp.and}{' '}
            </Typography>

            <Link
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-suma underline"
              legacyBehavior>
              <Typography
                variant="body"
                size="16px"
                className="cursor-pointer !font-semibold text-sumawealth-red-80 underline"
              >
                {contentfulSigUp.Privacy}
              </Typography>
            </Link>

            <Typography variant="body" size="16px">
              {' '}
              .
            </Typography>
          </div>

          <div className="text-center text-sm font-medium">
            <Link
              href={MASTERCARD_OFFER_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
              legacyBehavior>
              <Typography
                variant="body"
                size="16px"
                className="cursor-pointer !font-semibold text-sumawealth-red-80 underline"
              >
                {contentfulSigUp.offer}
              </Typography>
            </Link>
          </div>
        </Form>
      )}
    </Formik>)
  );
};
